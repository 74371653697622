var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_form_stock_adjustment") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "formStockAdjustment",
                attrs: {
                  model: _vm.form,
                  rules: _vm.formRules,
                  "label-align": "left"
                }
              },
              "a-form-model",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t(_vm.formProps.no.label) } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.documentNumber ||
                                  _vm.$t("lbl_auto_generate")
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.location.label),
                            prop: "location"
                          }
                        },
                        [
                          _c("CSelectRack", {
                            attrs: {
                              "for-list": false,
                              "is-disabled":
                                _vm.isModeView || _vm.isFormQtyDifference
                            },
                            model: {
                              value: _vm.form.location,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "location", $$v)
                              },
                              expression: "form.location"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.adj.label),
                            prop: "type"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  _vm.formProps.adj.placeholder
                                ),
                                disabled: _vm.isModeView
                              },
                              on: { change: _vm.onchangeType },
                              model: {
                                value: _vm.form.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type"
                              }
                            },
                            _vm._l(_vm.optType, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.key) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.date.label),
                            prop: "date"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm._f("date")(_vm.form.date)) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t(_vm.formProps.note.label) } },
                        [
                          _c("a-textarea", {
                            attrs: {
                              name: _vm.formProps.note.name,
                              placeholder: _vm.$t(
                                _vm.formProps.note.placeholder
                              ),
                              rows: { minRows: 1, maxRows: 4 },
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.form.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "notes", $$v)
                              },
                              expression: "form.notes"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.attachment.label),
                            help:
                              _vm.$t("lbl_upload_info_1") +
                              ". " +
                              _vm.$t("lbl_upload_info_3") +
                              ". " +
                              _vm.$t("lbl_upload_info_4")
                          }
                        },
                        [
                          (_vm.isNew || _vm.isDraft) && !_vm.form.attachment
                            ? _c(
                                "a-upload",
                                {
                                  attrs: {
                                    action: _vm.getBaseUrl,
                                    name: "file",
                                    multiple: false,
                                    "file-list": _vm.fileList,
                                    "before-upload": _vm.beforeUpload,
                                    remove: _vm.handleRemove,
                                    accept: ".pdf, .png, .jpg, .xlsx",
                                    headers: _vm.headers
                                  },
                                  on: { change: _vm.handleChangeAttachment }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        icon: "upload",
                                        disabled:
                                          _vm.isSubmitted || _vm.isCancelled
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("lbl_upload")))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c(
                                    "a-space",
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "ant-form-text",
                                          attrs: {
                                            href:
                                              _vm.urlAttachment ||
                                              "javascript:void(0)",
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                            download: _vm.form.attachment
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.form.attachment || "-")
                                          )
                                        ]
                                      ),
                                      _vm.isDraft
                                        ? _c("a-button", {
                                            attrs: {
                                              icon: "delete",
                                              size: "small",
                                              loading: _vm.loading.attachment
                                            },
                                            on: { click: _vm.deleteAttachment }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_status") } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(_vm._s(_vm.form.status || "-"))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        "tab-list": _vm.tabsMenu,
                        bordered: false,
                        "active-tab-key": _vm.activeTab
                      },
                      on: { tabChange: _vm.onTabChange },
                      scopedSlots: _vm._u([
                        {
                          key: "customRender",
                          fn: function(item) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm.$t(item.title)))
                            ])
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "component-fade", mode: "out-in" } },
                        [
                          _c(
                            "keep-alive",
                            [
                              _c(
                                _vm.dynamicComp,
                                _vm._g(
                                  _vm._b(
                                    { tag: "component" },
                                    "component",
                                    _vm.compProps,
                                    false
                                  ),
                                  _vm.compEvt
                                )
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.isNew || _vm.isDraft
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                loading: _vm.loading.draft,
                                disabled: _vm.isSubmitted || _vm.isCancelled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.validateForm("draft")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isNew || _vm.isDraft
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.submit,
                                disabled: _vm.isSubmitted || _vm.isCancelled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.validateForm("submit")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isWaitForApproval &&
                      _vm.hasPrivilegeStockOpnameApprove
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.approve,
                                disabled: _vm.isCancelled
                              },
                              on: { click: _vm.handleApprove }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }