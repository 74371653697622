


















































































































































































































import {
  PRICE_TYPE,
  RESPONSE_STOCK_TYPE,
  STATE_STOCK_ADJUSTMENT,
} from "@/models/enums/stock-adjustment.enum";
import { ResponseWarehouseLocation } from "@/models/interface-v2/warehouse.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import moment from "moment";
import Vue from "vue";
import { stockAdjustmentService } from "@/services-v2/stock-adjustment.service";
import {
  RequestStockAdjustment,
  ResponseStockAdjustment,
  ResponseStockAdjustmentLine,
} from "@/models/interface-v2/stock-adjustment.interface";
import { AdjustmentTableRow } from "./stock-table/TableAdjustment.vue";
import { OpnameTableRow } from "./stock-table/TableOpname.vue";
import { fileServices } from "@/services-v2/file.service";
import { MAXIMUM_FILE_SIZE } from "@/models/constant/global.constant";
import localStorageService from "@/services/localStorage.service";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { IOption } from "@/models/interface-v2/common.interface";
import {
  DifferenceQtyCreate,
  DifferenceQtyCreateLine,
} from "@/models/interface-v2/difference-qty.interface";
import { RowQtyDiff } from "./stock-table/TableQtyDifference.vue";
import { FormModel } from "ant-design-vue";

const OPT_TYPE = [
  {
    key: "Stock Adjustment",
    value: RESPONSE_STOCK_TYPE.STOCK_ADJUSTMENT,
  },
  {
    key: "Stock Opname",
    value: RESPONSE_STOCK_TYPE.STOCK_OPNAME,
  },
  {
    key: "Difference Qty",
    value: RESPONSE_STOCK_TYPE.DIFFERENCE_QTY,
  },
];

export default Vue.extend({
  name: "FormStockAdjustment",
  components: {
    TableOpname: () =>
      import(/* webpackPrefetch: true */ "./stock-table/TableOpname.vue"),
    TableAdjustment: () =>
      import(/* webpackPrefetch: true */ "./stock-table/TableAdjustment.vue"),
    TableQtyDifference: () =>
      import(
        /* webpackPrefetch: true */ "./stock-table/TableQtyDifference.vue"
      ),
  },
  mixins: [MNotificationVue],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        documentNumber: "",
        location: undefined as string | undefined,
        type: RESPONSE_STOCK_TYPE.STOCK_OPNAME as RESPONSE_STOCK_TYPE,
        date: moment().format(),
        notes: "",
        attachment: "",
        stockAdjustmentLines: [] as any[],
        status: STATE_STOCK_ADJUSTMENT.NEW as STATE_STOCK_ADJUSTMENT,
        deletedStockAdjustmentIds: [] as string[],
      },
      formRules: {
        location: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        type: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
        date: [
          {
            required: true,
            trigger: "change",
            message: this.$t("lbl_validation_required_error"),
          },
        ],
      },
      formProps: {
        no: {
          label: "lbl_document_number",
          name: "document number",
          placeholder: "lbl_auto_generate",
        },
        location: {
          label: "lbl_location",
          name: "Location",
          placeholder: "lbl_choose",
        },
        adj: {
          label: "lbl_adjustment_type",
          name: "Adj. Type",
          placeholder: "lbl_choose",
        },
        date: {
          label: "lbl_date",
          name: "Date",
          placeholder: "lbl_choose",
        },
        note: {
          label: "lbl_note",
          name: "Note",
          placeholder: "lbl_type_here",
        },
        attachment: {
          label: "lbl_attachment",
          name: "Attachment",
          placeholder: "lbl_choose",
        },
      },
      loading: {
        location: false,
        submit: false,
        draft: false,
        cancel: false,
        table: false,
        attachment: false,
        approve: false,
      },
      dtListLocation: [] as ResponseWarehouseLocation[],
      optType: OPT_TYPE,
      optLocation: [] as IOption[],
      fileList: [] as any[],
      tabsMenu: [
        {
          key: "opname",
          title: "lbl_stock_opname",
          comp: "TableOpname",
          disabled: false,
          scopedSlots: { tab: "customRender" },
        },
        {
          key: "adjustment",
          title: "lbl_stock_adjustment",
          comp: "TableAdjustment",
          disabled: false,
          scopedSlots: { tab: "customRender" },
        },
        {
          key: "qtyDifference",
          title: "lbl_qty_difference",
          comp: "TableQtyDifference",
          disabled: false,
          scopedSlots: { tab: "customRender" },
        },
      ],
      activeTab: "opname",
      reportId: "",
      detailReports: {} as ResponseStockAdjustment,
      headers: {
        authorization: "Bearer " + this.$store.state.access_token,
      },
      uploadProses: false as boolean,
      urlAttachment: "" as string | ArrayBuffer | null,
      attachmentName: "",
      userPrivileges: [] as IAuthorities[],
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: {
          sm: {
            span: 24,
          },
          md: {
            span: 9,
          },
        },
        wrapperCol: {
          sm: {
            span: 24,
          },
          md: {
            span: 15,
          },
        },
      };
    },
    dynamicComp(): string {
      return this.tabsMenu.find((x) => x.key === this.activeTab)?.comp || "";
    },
    compProps() {
      return {
        locationId: this.form.location || "",
        detailReports: this.detailReports,
      };
    },
    compEvt() {
      return {
        onDataUpdate: this.onDataUpdate,
      };
    },
    isFormOpname(): boolean {
      return this.form.type === RESPONSE_STOCK_TYPE.STOCK_OPNAME;
    },
    isFormAdjustment(): boolean {
      return this.form.type === RESPONSE_STOCK_TYPE.STOCK_ADJUSTMENT;
    },
    isFormQtyDifference(): boolean {
      return this.form.type === RESPONSE_STOCK_TYPE.DIFFERENCE_QTY;
    },
    isNew(): boolean {
      return this.form.status === STATE_STOCK_ADJUSTMENT.NEW;
    },
    isSubmitted(): boolean {
      return this.form.status === STATE_STOCK_ADJUSTMENT.SUBMITTED;
    },
    isCancelled(): boolean {
      return this.form.status === STATE_STOCK_ADJUSTMENT.CANCELLED;
    },
    isDraft(): boolean {
      return this.form.status === STATE_STOCK_ADJUSTMENT.DRAFT;
    },
    isWaitForApproval(): boolean {
      return this.form.status === STATE_STOCK_ADJUSTMENT.WAIT_FOR_APPROVAL;
    },
    getBaseUrl(): string {
      return fileServices.baseUrl();
    },
    hasPrivilegeStockOpnameApprove(): boolean {
      return !!this.userPrivileges.find(
        (x) => x.key === "approval-stock-opname"
      );
    },
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
  },
  created() {
    this.loadUserPrivileges();
    if (this.$route.params.id) {
      this.reportId = this.$route.params.id;
      this.getDetail();
    }
  },
  methods: {
    moment,
    updateApproval(
      id: string,
      payload: RequestStockAdjustment
    ): Promise<ResponseStockAdjustment> {
      return stockAdjustmentService.updateApproval(id, payload);
    },
    submitApproval(id: string): Promise<ResponseStockAdjustment> {
      return stockAdjustmentService.submitApproval(id);
    },
    createStockAdjustment(
      payload: RequestStockAdjustment
    ): Promise<ResponseStockAdjustment> {
      return stockAdjustmentService.createStockAdjustment(payload);
    },
    submitStockAdjustment(id: string): Promise<ResponseStockAdjustment> {
      return stockAdjustmentService.submitStockAdjustment(id);
    },
    cancelStockAdjustment(id: string): Promise<ResponseStockAdjustment> {
      return stockAdjustmentService.cancelStockAdjustment(id);
    },
    updateStockAdjusment(
      id: string,
      payload: RequestStockAdjustment
    ): Promise<ResponseStockAdjustment> {
      return stockAdjustmentService.updateStockAdjustment(payload, id);
    },
    getDetailStockAdjusment(id: string): Promise<ResponseStockAdjustment> {
      return stockAdjustmentService.getDetailStockAdjustment(id);
    },
    loadUserPrivileges(): void {
      const privileges = localStorageService.loadUserPrivilege();
      this.userPrivileges = privileges;
    },
    disabledDate(current) {
      return current > this.moment().endOf("day");
    },
    handleChangeAttachment(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
      if (info.file.status !== "uploading") {
        this.uploadProses = true;
      }
      if (info.file.status === "done") {
        this.uploadProses = false;
        this.attachmentName = info.file.response.objectName;
        this.$message.success(
          this.$t("notif_file_upload_successfully", {
            filename: info.file.name,
          }).toString()
        );
      } else if (info.file.status === "error") {
        this.uploadProses = true;
        this.$message.error(
          this.$t("notif_file_upload_failed", {
            filename: info.file.name,
          }).toString()
        );
      }
    },
    beforeUpload(file): boolean {
      const isLt5M = file.size;
      if (isLt5M >= MAXIMUM_FILE_SIZE) {
        this.$message.error(this.$t("lbl_upload_info_1").toString());
        return false;
      }
      return true;
    },
    async handleRemove(file): Promise<void> {
      try {
        if (file.response) {
          await fileServices.deleteFile(file.response.objectName);
          this.$message.success(
            this.$t("notif_file_delete_success").toString()
          );
          this.fileList = [];
        } else {
          const index = this.fileList.indexOf(file);
          const newFileList = this.fileList.slice();
          newFileList.splice(index, 1);
          this.fileList = newFileList;
        }
      } catch (error) {
        this.$message.error(this.$t("notif_file_delete_fail").toString());
      }
    },
    async getAttachment(filename: string): Promise<void> {
      try {
        const blob = await fileServices.getFile(filename);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.urlAttachment = reader.result;
        };
      } catch (error) {
        this.$message.error(
          this.$t("notif_file_get_fail", { filename }).toString()
        );
      }
    },
    async deleteAttachment(): Promise<void> {
      try {
        this.loading.attachment = true;
        await fileServices.deleteFile(this.form.attachment);
        const payload: RequestStockAdjustment = {
          attachment: "",
          date: this.form.date,
          notes: this.form.notes,
          stockAdjustmentLines: this.form.stockAdjustmentLines,
          type: this.form.type,
          warehouseLocationId: this.form.location || "",
          deletedStockAdjustmentIds: [],
        };
        await this.updateStockAdjusment(this.reportId, payload);
        this.getDetail();
        this.$message.success(this.$t("notif_file_delete_success").toString());
      } catch (error) {
        this.$message.error(this.$t("notif_file_delete_fail").toString());
      } finally {
        this.loading.attachment = false;
      }
    },
    onTabChange(key: "opname" | "adjustment" | "qtyDifference") {
      this.activeTab = key;
      if (key === "opname") {
        this.form.type = RESPONSE_STOCK_TYPE.STOCK_OPNAME;
      } else if (key === "adjustment") {
        this.form.type = RESPONSE_STOCK_TYPE.STOCK_ADJUSTMENT;
      } else if (key === "qtyDifference") {
        this.form.type = RESPONSE_STOCK_TYPE.DIFFERENCE_QTY;
        this.formRules.location[0].required = false;
      }
    },
    handleBack(): void {
      this.$router.push({ name: "logistic.stockadjustment.list" });
    },
    handleCancel(): void {
      this.showConfirmation("lbl_confirm_cancel").then(async (val: boolean) => {
        if (val) {
          try {
            if (this.reportId) {
              this.loading.cancel = true;
              await this.cancelStockAdjustment(this.reportId);
              this.handleBack();
              this.$message.success(this.$t("notif_cancel_success").toString());
            }
          } catch (error) {
            this.$message.error(this.$t("notif_cancel_error").toString());
          } finally {
            this.loading.cancel = false;
          }
        }
      });
    },
    constructRequestCreate(): RequestStockAdjustment {
      const { form } = this;
      return {
        attachment: this.attachmentName,
        date: form.date,
        notes: form.notes,
        stockAdjustmentLines: form.stockAdjustmentLines,
        type: form.type,
        warehouseLocationId: form.location || "",
        deletedStockAdjustmentIds: form.deletedStockAdjustmentIds,
      };
    },
    validateTable(): boolean {
      const lines = this.form.stockAdjustmentLines;
      let valid = true;
      if (!lines.length) {
        valid = false;
      } else {
        const found = lines.find((item) => !item.productId || !item.uomId);
        if (found) {
          valid = false;
        }
      }
      return valid;
    },
    validateForm(method: "submit" | "draft"): void {
      const form = this.$refs.formStockAdjustment as FormModel;
      if (method === "draft") {
        if (this.reportId) {
          if (this.form.type === RESPONSE_STOCK_TYPE.DIFFERENCE_QTY) {
            this.updateDiffQty();
          } else {
            // update stock opname/adjustment
            this.handleUpdate();
          }
        } else {
          if (this.form.type === RESPONSE_STOCK_TYPE.DIFFERENCE_QTY) {
            this.createDiffQty();
          } else {
            // create draft stock opname/adjustment
            this.handleSaveDraft();
          }
        }
      } else {
        form.validate((valid) => {
          if (valid && this.validateTable()) {
            if (this.reportId) {
              this.handleSubmit(this.reportId);
            } else {
              if (this.form.type === RESPONSE_STOCK_TYPE.DIFFERENCE_QTY) {
                // create draft and s ubmit stock diff qty
                this.createSubmitDiffQty();
              } else {
                // create draft and submit stock opname/adjustment
                this.handleSubmitNew();
              }
            }
          } else {
            this.showNotifValidationError();
          }
        });
      }
    },
    async handleSubmit(id: string): Promise<void> {
      try {
        this.loading.submit = true;
        const report = await this.submitStockAdjustment(id);
        this.showSubmitSuccessMesssage(report.documentNumber);
        this.handleBack();
      } catch (error) {
        this.$message.error(this.$t("notif_submit_fail").toString());
      } finally {
        this.loading.submit = false;
      }
    },
    async handleSubmitNew(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload: RequestStockAdjustment = this.constructRequestCreate();
        const report = await this.createStockAdjustment(payload);
        await this.submitStockAdjustment(report.id);
        this.showSubmitSuccessMesssage(report.documentNumber);
        this.handleBack();
      } catch (error) {
        this.$message.error(this.$t("notif_submit_fail").toString());
      } finally {
        this.loading.submit = false;
      }
    },
    async handleSaveDraft(): Promise<void> {
      try {
        this.loading.draft = true;
        const payload: RequestStockAdjustment = this.constructRequestCreate();
        const report = await this.createStockAdjustment(payload);
        this.showSubmitSuccessMesssage(report.documentNumber);
        this.handleBack();
      } catch (error) {
        this.$message.error(this.$t("notif_submit_fail").toString());
      } finally {
        this.loading.draft = false;
      }
    },
    async handleUpdate(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload = this.constructRequestCreate();
        await this.updateStockAdjusment(this.reportId, payload);
        this.$message.success(this.$t("notif_update_success").toString());
        this.handleBack();
      } catch (error) {
        this.$message.error(this.$t("notif_update_fail").toString());
      } finally {
        this.loading.submit = false;
      }
    },
    handleApprove(): void {
      if (this.isFormQtyDifference) {
        this.handleQtyDiffApprove();
      } else {
        this.handleSubmitApproval();
      }
    },
    async handleQtyDiffApprove(): Promise<void> {
      try {
        this.loading.approve = true;
        const payload = this.createPayloadDiffQty();
        await stockAdjustmentService.differenceQtyApprovalUpdate(
          this.reportId,
          payload
        );
        await stockAdjustmentService.differenceQtyApproval(this.reportId);
        this.showSuccessMessage("notif_approved_success");
        this.getDetail();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.approve = false;
      }
    },
    async handleSubmitApproval(): Promise<void> {
      try {
        this.loading.approve = true;
        const payload = this.constructRequestCreate();
        await this.updateApproval(this.reportId, payload);
        await this.submitApproval(this.reportId);
        this.showSuccessMessage("notif_approved_success");
        this.getDetail();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.approve = false;
      }
    },
    onDataUpdate(evt: {
      name: "opname" | "adjustment" | "qtyDifference";
      data: AdjustmentTableRow[] | OpnameTableRow[] | RowQtyDiff[];
      deletedRow: number[];
    }): void {
      this.form.stockAdjustmentLines = [];
      this.form.deletedStockAdjustmentIds = [];
      if (evt.name === "opname") {
        evt.data.forEach((item) => {
          this.form.stockAdjustmentLines.push({
            physicalQty: item.qtyPhysical,
            productId: item.productId,
            qty: item.qtyTotal,
            uomId: item.uomId,
            id: item.id,
            batchId: item.batchId,
            qualityControl: { condition: item.condition },
            priceType: PRICE_TYPE.LATEST_PRICE,
          });
        });
      } else if (evt.name === "adjustment") {
        evt.data.forEach((item) => {
          this.form.stockAdjustmentLines.push({
            physicalQty: 0, // stock adjustment doesnt need physical qty
            productId: item.productId,
            qty: item.qty,
            uomId: item.uomId,
            id: item.id,
            batchId: item.batchId,
            qualityControl: { condition: "" },
            priceType: PRICE_TYPE.LATEST_PRICE,
          });
        });
      } else if (evt.name === "qtyDifference") {
        evt.data.forEach((item) => {
          this.form.stockAdjustmentLines.push({
            batchId: item.batchId,
            id: item.id || null,
            locationId: item.locationId,
            physicalQty: item.physicalQty,
            priceType: PRICE_TYPE.LATEST_PRICE,
            productId: item.productId,
            qty: item.qty,
            qualityControl: { condition: item.qualityControl.condition },
            uomId: item.uomId,
          });
        });
      }
      if (evt.deletedRow.length) {
        evt.deletedRow.forEach((x) => {
          this.form.deletedStockAdjustmentIds.push(
            this.detailReports.stockAdjustmentLines[x].id
          );
        });
      }
    },
    onchangeType(): void {
      this.formRules.location[0].required = true;
      this.formRules.type[0].required = true;
      if (this.isFormOpname) this.activeTab = "opname";
      else if (this.isFormAdjustment) this.activeTab = "adjustment";
      else if (this.isFormQtyDifference) {
        this.activeTab = "qtyDifference";
        this.formRules.location[0].required = false;
        this.formRules.type[0].required = false;
      }
    },
    async getDetail(): Promise<void> {
      try {
        this.loading.table = true;
        const detail = await this.getDetailStockAdjusment(this.reportId);
        if (detail.type === RESPONSE_STOCK_TYPE.STOCK_ADJUSTMENT) {
          this.activeTab = "adjustment";
          this.tabsMenu[0].disabled = this.isModeView;
          this.tabsMenu[2].disabled = this.isModeView;
        } else if (detail.type === RESPONSE_STOCK_TYPE.STOCK_OPNAME) {
          this.activeTab = "opname";
          this.tabsMenu[1].disabled = this.isModeView;
          this.tabsMenu[2].disabled = this.isModeView;
        } else if (detail.type === RESPONSE_STOCK_TYPE.DIFFERENCE_QTY) {
          this.activeTab = "qtyDifference";
          this.tabsMenu[0].disabled = this.isModeView;
          this.tabsMenu[1].disabled = this.isModeView;
          this.formRules.location[0].required = false;
          this.formRules.type[0].required = false;
        }
        this.form = {
          documentNumber: detail.documentNumber,
          location: detail.warehouseLocationId,
          type: detail.type,
          date: detail.date,
          notes: detail.notes,
          attachment: detail.attachment,
          status: detail.state,
          stockAdjustmentLines: [],
          deletedStockAdjustmentIds: [],
        };
        this.detailReports = detail;
        const loc = this.optLocation.find(
          (x) => x.value === this.form.location
        );
        if (!loc) {
          this.optLocation.push({
            key: detail.warehouseLocation,
            value: detail.warehouseLocationId,
          });
        }
        this.assignStockAdjustmentLines(detail.stockAdjustmentLines);
        if (this.form.attachment) this.getAttachment(this.form.attachment);
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.table = false;
      }
    },
    assignStockAdjustmentLines(lines: ResponseStockAdjustmentLine[]): void {
      if (lines && lines.length) {
        this.form.stockAdjustmentLines = [];
        if (this.form.type === RESPONSE_STOCK_TYPE.DIFFERENCE_QTY) {
          lines.forEach((x) => {
            this.form.stockAdjustmentLines.push({
              batchId: x.batchNumberId,
              id: x.id,
              locationId: x.locationId,
              physicalQty: x.physicalQty,
              priceType: x.priceType,
              productId: x.product.id,
              qty: x.qty,
              qualityControl: x.qualityControl.condition,
              uomId: x.uom.id,
            });
          });
        } else {
          lines.forEach((item) => {
            this.form.stockAdjustmentLines.push({
              physicalQty: item.physicalQty,
              productId: item.product.id,
              qty: item.qty,
              id: item.id,
              uomId: item.uom.id,
              batchId: item.batchNumberId,
              qualityControl: { condition: item.qualityControl.condition },
              priceType: item.priceType,
            });
          });
        }
      }
    },
    createPayloadDiffQty(): DifferenceQtyCreate {
      return {
        attachment: this.form.attachment,
        date: this.form.date,
        deletedStockAdjustmentIds: this.form.deletedStockAdjustmentIds,
        lineRequestDTOS: this.form
          .stockAdjustmentLines as DifferenceQtyCreateLine[],
        notes: this.form.notes,
      };
    },
    async createDiffQty(): Promise<void> {
      try {
        const payload: DifferenceQtyCreate = this.createPayloadDiffQty();
        const { id, state } = await stockAdjustmentService.differenceQtyCreate(
          payload
        );
        this.showCreateSuccessMesssage();
        this.$router.replace({
          name: "logistic.stockadjustment.edit",
          params: { id },
          query: { status: state.toLowerCase() },
        });
      } catch (error) {
        this.showNotifFailCreate();
      }
    },
    async updateDiffQty(): Promise<void> {
      try {
        const payload: DifferenceQtyCreate = this.createPayloadDiffQty();
        await stockAdjustmentService.differenceQtyUpdate(
          this.reportId,
          payload
        );
        this.getDetail();
        this.showUpdateSuccessMesssage();
      } catch (error) {
        this.showNotifFailUpdate();
      }
    },
    async createSubmitDiffQty(): Promise<void> {
      try {
        const payload: DifferenceQtyCreate = this.createPayloadDiffQty();
        const { id } = await stockAdjustmentService.differenceQtyCreate(
          payload
        );
        await stockAdjustmentService.submitStockAdjustment(id);
        this.showSubmitSuccessMesssage();
        this.reportId = id;
        this.getDetail();
      } catch (error) {
        this.showSubmitFailedMesssage();
      }
    },
  },
});
