export enum STATE_STOCK_ADJUSTMENT {
  DRAFT = "Draft",
  CANCELLED = "Cancelled",
  SUBMITTED = "Submitted",
  WAIT_FOR_APPROVAL = "Wait For Approval",

  // local enum
  NEW = "New"
}

export enum RESPONSE_STOCK_TYPE {
  STOCK_ADJUSTMENT = "Stock Adjustment",
  STOCK_OPNAME = "Stock Opname",
  DIFFERENCE_QTY = "Difference Qty",
}

export enum SEARCH_STOCK_TYPE {
  STOCK_ADJUSTMENT = "STOCK_ADJUSTMENT",
  STOCK_OPNAME = "STOCK_OPNAME"
}

export enum PRICE_TYPE {
  LATEST_PRICE = "Latest Price",
  ZERO = "Rp 0"
}
