import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";
import {
  RequestStockAdjustment,
  ResponseListStockAdjustment,
  ResponseStockAdjustment,
} from "@/models/interface-v2/stock-adjustment.interface";
import { DifferenceQtyCreate } from "@/models/interface-v2/difference-qty.interface";

export class StockAdjustmentService extends HttpClient {
  public constructor() {
    super();

    this.downloadStockAdjustment = this.downloadStockAdjustment.bind(
      this
    );
    this.printStockAdjustment = this.printStockAdjustment.bind(this);
    this.getDetailStockAdjustment = this.getDetailStockAdjustment.bind(this);
    this.getStockAdjustment = this.getStockAdjustment.bind(this);
    this.createStockAdjustment = this.createStockAdjustment.bind(this);
    this.submitStockAdjustment = this.submitStockAdjustment.bind(
      this
    );
    this.cancelStockAdjustment = this.cancelStockAdjustment.bind(
      this
    );
  }

  public downloadStockAdjustment(
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.StockAdjustment + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public printStockAdjustment(
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.StockAdjustment + "/report/print", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public getDetailStockAdjustment(id: string): Promise<ResponseStockAdjustment> {
    return this.get<ResponseStockAdjustment>(Api.StockAdjustment + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public getStockAdjustment(
    params: RequestQueryParamsModel
  ): Promise<ResponseListStockAdjustment> {
    return this.get<ResponseListStockAdjustment>(Api.StockAdjustment, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createStockAdjustment(
    payload: RequestStockAdjustment
  ): Promise<ResponseStockAdjustment> {
    return this.post<
      ResponseStockAdjustment,
      RequestStockAdjustment
    >(Api.StockAdjustment, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public submitStockAdjustment(
    id: string
  ): Promise<ResponseStockAdjustment> {
    return this.put<ResponseStockAdjustment, null>(
      Api.StockAdjustment + `/submit/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public cancelStockAdjustment(
    id: string
  ): Promise<ResponseStockAdjustment> {
    return this.put<ResponseStockAdjustment, null>(
      Api.StockAdjustment + `/cancel/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateStockAdjustment(
    payload: RequestStockAdjustment,
    id: string
  ): Promise<ResponseStockAdjustment> {
    return this.put<
      ResponseStockAdjustment,
      RequestStockAdjustment
    >(Api.StockAdjustment + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateApproval(id: string, payload: RequestStockAdjustment): Promise<ResponseStockAdjustment> {
    return this.put<ResponseStockAdjustment, RequestStockAdjustment>(`${Api.StockAdjustment}/approval/update/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  
  submitApproval(id: string): Promise<ResponseStockAdjustment> {
    return this.put<ResponseStockAdjustment, null>(`${Api.StockAdjustment}/approval/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  differenceQtyCreate(payload: DifferenceQtyCreate): Promise<ResponseStockAdjustment> {
    return this.post<ResponseStockAdjustment, DifferenceQtyCreate>(`${Api.StockAdjustment}/difference-qty`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  differenceQtyUpdate(id: string, payload: DifferenceQtyCreate): Promise<ResponseStockAdjustment> {
    return this.put<ResponseStockAdjustment, DifferenceQtyCreate>(`${Api.StockAdjustment}/difference-qty/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  differenceQtyApproval(id: string): Promise<ResponseStockAdjustment> {
    return this.put<ResponseStockAdjustment, null>(`${Api.StockAdjustment}/difference-qty/approval/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  differenceQtyApprovalUpdate(id: string, payload: DifferenceQtyCreate): Promise<ResponseStockAdjustment> {
    return this.put<ResponseStockAdjustment, DifferenceQtyCreate>(`${Api.StockAdjustment}/difference-qty/approval/update/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const stockAdjustmentService = new StockAdjustmentService();
